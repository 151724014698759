<template>
  <v-container fluid class="" v-if="datosPrincipales.idshipment">
    <v-form ref="form">
      <h3 id="tituloCarga" class="text-center" color="white">
        DATOS DE LA CARGA
      </h3>
      <div class="row mt-3 mx-1">
        <div class="col-sm-6 col-md-6">
          <v-autocomplete
            dense
            :items="portBegin"
            :label="'Origen'"
            v-model="datosPrincipales.idPortBegin"
            :rules="[(v) => !!v || 'El ' + 'Origen' + ' es requerido']"
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
          ></v-autocomplete>
        </div>
        <div class="col-sm-6 col-md-6">
          <v-autocomplete
            dense
            :items="portEnd"
            :label="'Destino'"
            v-model="datosPrincipales.idPortEnd"
            :rules="[(v) => !!v || 'El ' + 'Origen' + ' es requerido']"
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
          ></v-autocomplete>
        </div>
      </div>
      <!-- INFO DE ERROR  -->
      <p
        style="text-align: center !important"
        v-if="
          (datosPrincipales.idshipment.value != 2 &&
            (!datosPrincipales.bultos ||
              !datosPrincipales.kg ||
              !datosPrincipales.metroscc)) ||
          (datosPrincipales.idshipment.value == 2 &&
            datosPrincipales.containers.length == 0)
        "
      >
        <v-icon color="red">mdi-arrow-down-left-bold</v-icon>
        <b>LLENAR ESTOS CAMPOS</b>
        <v-icon color="red">mdi-arrow-down-right-bold</v-icon>
      </p>

      <!--NO FCL -->

      <div
        v-show="
          datosPrincipales.idshipment
            ? datosPrincipales.idshipment.value != 2 &&
              datosPrincipales.idshipment != 2 &&
              datosPrincipales.idshipment.value != 5 &&
              datosPrincipales.idshipment != 5
            : false
        "
      >
        <div class="row" v-if="tipo != 'ver'">
          <div class="col-4">
            <v-text-field
              class="pa-1"
              type="number"
              min="1"
              suffix="uni."
              label="Cantidad de Bulto"
              placeholder="Cantidad de Bultos"
              required
              v-model="tblTotal.uni"
              dense
            >
            </v-text-field>
          </div>
          <div class="col-4">
            <v-text-field
              type="number"
              class="pa-1"
              min="1"
              label="Metro Cúbicos"
              placeholder="Metro Cúbicos..."
              dense
              v-model="tblTotal.volumen"
              suffix="m3"
            >
            </v-text-field>
          </div>
          <div class="col-4">
            <v-text-field
              class="pa-1"
              type="number"
              min="1"
              label="Kilogramos"
              placeholder="Kilogramos..."
              dense
              suffix="kg."
              v-model="tblTotal.peso"
            >
            </v-text-field>
          </div>
          <span class="red--text my-1">{{ errorMedidas }}</span>
        </div>
        <v-flex text-center class="my-2" v-if="tipo != 'ver'">
          <v-btn
            class="me-2"
            color="light-blue white--text"
            small
            @click="actualizar"
          >
            AGREGAR
          </v-btn>
          <v-btn
            small
            class="orange darken-4 white--text"
            dark
            @click="calculadoraFlag = true"
          >
            Usar calculadora
          </v-btn>
        </v-flex>
        <v-simple-table dense class="elevation-1 my-2">
          <template v-slot:default>
            <thead class="teal lighten-2 white--text">
              <tr>
                <td>Cant. Bultos</td>
                <td>Peso (Kg.)</td>
                <td>Volumen (m <sup>3</sup>)</td>
                <td v-if="tipo != 'ver'"></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ datosPrincipales.bultos }} uni.</td>
                <td>{{ datosPrincipales.kg }} kg.</td>
                <td>{{ datosPrincipales.metroscc }} m<sup>3</sup></td>
                <td v-if="tipo != 'ver'">
                  <v-btn icon color="red" @click="limpiiarTablaCarga()">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <!-- NO FCL -->
      <!-- FCL -->
      <div
        v-show="
          datosPrincipales.idshipment
            ? (datosPrincipales.idshipment.value == 2 &&
                datosPrincipales.idshipment.value) ||
              datosPrincipales.idshipment == 2 ||
              (datosPrincipales.idshipment.value == 5 &&
                datosPrincipales.idshipment.value) ||
              datosPrincipales.idshipment == 5
            : false
        "
      >
        <h2 class="ma-1">Datos sobre Contenedor</h2>
        <v-flex text-right>
          <v-btn
            small
            outlined
            elevation="1"
            class="ms-2"
            color="success white--text"
            @click="containerFlag = true"
          >
            Añadir
          </v-btn>
        </v-flex>
        <v-simple-table dense class="elevation-5 my-2">
          <thead class="teal lighten-2 white--text">
            <tr>
              <th></th>
              <th style="width: 10%">#</th>
              <th style="width: 50%">Tipo Contenedor</th>
              <th style="width: 20%">Cantidad</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(containersList, i) in this.datosPrincipales.containers"
              :key="i"
            >
              <td>
                <v-btn icon color="red" @click="eliminarContenedor(i)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
              <td>
                {{ i + 1 }}
              </td>
              <td>
                {{ containersList.description }}
              </td>
              <td>
                <v-text-field
                  dense
                  hide-details
                  autocomplete="none"
                  type="number"
                  step="1"
                  min="1"
                  v-model="containersList.cantidad"
                  suffix="uni"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <v-flex text-right class="ma-auto" v-if="tipo == 'nuevo'">
        <v-btn
          class="mx-1"
          color="#5AB55E"
          small
          scrollable
          @click="aceptar()"
          :to="{ name: 'cardServicio' }"
        >
          <b>PASO 3</b> <v-icon class="mx-0">mdi-chevron-right</v-icon>
        </v-btn>
      </v-flex>
    </v-form>
    <!-- MODALES -->
    <v-dialog v-model="calculadoraFlag" max-width="500px">
      <v-card>
        <calculadora />
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="containerFlag"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pa-10">
        <h3>Añadir Contenedor...</h3>
        <v-form ref="formContainer">
          <v-autocomplete
            :items="containersList"
            :rules="[(v) => !!v || 'El tipo contenedor es requerido']"
            label="Contenedores"
            v-model="formulario.id"
          ></v-autocomplete>
          <v-text-field
            autocomplete="off"
            type="number"
            name="name"
            label="Cantidad"
            :rules="[(v) => !!v || 'La cantidad es requerida']"
            v-model="formulario.cantidad"
          ></v-text-field>

          <span class="red--text">{{ errorContenedor }}</span>
          <v-flex text-right>
            <v-btn x-small color="success" @click="guardarContainer">
              Aceptar
            </v-btn>
          </v-flex>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent width="400">
      <v-card color="info" dark class="py-5">
        <v-card-text>
          Un momento por favor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import funcion from "@/mixins/funciones";
import Swal from "sweetalert2";
export default {
  props: ["tipo"],
  mixins: [funcion],
  components: {
    calculadora: () => import("../../components/comun/calculadorComponet.vue"),
  },
  name: "cardDatosCargaComponent",
  methods: {
    ...mapMutations([
      "updateDatosServicios",
      "setContainers",
      "setCalculadorFlag",
      "setDialog",
      "setFactor",
    ]),
    limpiiarTablaCarga() {
      this.datosPrincipales.bultos = "";
      this.datosPrincipales.kg = "";
      this.datosPrincipales.metroscc = "";
    },
    eliminarContenedor(i) {
      this.datosPrincipales.containers.splice(i, 1);
    },
    aceptar() {
      if (
        (this.$refs.form.validate() &&
          (this.datosPrincipales.idshipment.value == 2 ||
            this.datosPrincipales.idshipment.value == 5) &&
          this.datosPrincipales.containers.length > 0) ||
        (this.datosPrincipales.idshipment.value != 2 &&
          this.datosPrincipales.idshipment.value != 5 &&
          this.datosPrincipales.bultos)
      ) {
        this.setDialog(true);
        this.updateDatosServicios();
        setTimeout(
          this.$router
            .push({
              name: "cardServicio",
              hash: "#servicio",
            })
            .catch(() => {}),
          5000
        );
      } else {
        if (
          (this.datosPrincipales.idshipment.value == 2 ||
            this.datosPrincipales.idshipment.value == 5) &&
          this.datosPrincipales.containers.length <= 0
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "SE REQUIERE AL MENOS UN CONTENEDOR",
          });
        } else if (
          !(
            this.datosPrincipales.idshipment.value != 2 &&
            this.datosPrincipales.idshipment.value != 5 &&
            this.datosPrincipales.bultos
          )
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "SE REQUIERE LOS DATOS DE LA CARGA",
          });
        }
      }
    },

    guardarContainer() {
      this.errorContenedor = "";

      if (this.$refs.formContainer.validate()) {
        if (
          this.datosPrincipales.containers.filter(
            (v) => v.id == this.formulario.id
          ).length == 0
        ) {
          let c = this.containersList.filter(
            (v) => v.value == this.formulario.id
          )[0];
          this.setContainers({
            id: this.formulario.id,
            description: c.text,
            cantidad: this.formulario.cantidad,
          });
          this.containerFlag = false;
          this.$refs.formContainer.reset();
        } else {
          this.errorContenedor = "El contenedor ya se encuentra en la lista.";
        }
      }
    },
    actualizar() {
      this.errorMedidas = "";
      if (!this.tblTotal.uni || !this.tblTotal.peso || !this.tblTotal.volumen) {
        this.errorMedidas = "Todos los datos son requeridos.";
      } else {
        let data = {
          bultos: this.tblTotal.uni,
          kg: this.tblTotal.peso,
          metroscc: this.tblTotal.volumen,
        };
        this.setCalculadorFlag(data);
      }
      this.tblTotal.uni = "";
      this.tblTotal.peso = "";
      this.tblTotal.volumen = "";
    },
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      items: [],
      formulario: {
        id: null,
        cantidad: null,
      },
      calculadoraFlag: false,
      containerFlag: false,
      errorContenedor: "",
      errorMedidas: "",
      tblTotal: { uni: "", peso: "", volumen: "" },
    };
  },
  computed: {
    ...mapState([
      "portBegin",
      "portEnd",
      "calculadorFlag",
      "datosPrincipales",
      "containersList",
      "dialog",
    ]),
  },

  watch: {
    calculadorFlag() {
      this.calculadoraFlag = false;
    },
  },
};
</script>

<style></style>
